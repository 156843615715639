import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole, HttpClient } from "@sentry/integrations";

export default function sentryInit() {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ["error"]
      }),
      new HttpClient({
        failedRequestStatusCodes: [500, 599],
        failedRequestTargets: []
      })
    ],
    environment: process.env.VITE_VERCEL_ENV ?? "development",
    enabled: true,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.25
  });
}
