import React from "react";
import ReactDOM from "react-dom/client";
import sentryInit from "@/utils/sentryInit";

try {
  sentryInit();
  console.log("sentry init was successful");
} catch (error) {
  console.error("sentry init error", error);
}

const Routes = React.lazy(() => import("./routes"));

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>
);
